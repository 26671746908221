<template>
  <div id="index" ref="appRef">
    <audio ref="audio" :src="music_path"></audio>

    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="margin-top: 70px">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']" />
            <div class="title">
              <span class="title-text">销售管理订单系统</span>
              <button @click="playStart" v-if="!is_play">开始播放语音</button>
              <button @click="playStop" v-if="is_play">停止播放语音</button>

              <dv-decoration-6
                class="dv-dec-6"
                :reverse="true"
                :color="['#50e3c2', '#67a1e5']"
              />
            </div>
            <dv-decoration-8
              class="dv-dec-8"
              :reverse="true"
              :color="['#568aea', '#000000']"
            />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>
        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width"></div>
          <div class="d-flex aside-width">
            <div class="react-right mr-4 react-l-s">
              <span class="react-after"></span>
              <span class="text"
                >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span
              >
            </div>
          </div>
        </div>
        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div style="width:33%;display:inline-block;">
              <dv-decoration-9
                :dur="order_data.no_dispose_order.dur"
                style="
                  width: 300px;
                  height: 300px;
                  text-align: center;
                  font-size: 30px;
                  margin: 0 auto
                "
              >
                待处理<br /><br />
                {{ order_data.no_dispose_order.num }}单
              </dv-decoration-9>
            </div>
            <div style="width:33%;display:inline-block;">
              <dv-decoration-9
                :dur="order_data.dispose_order.dur"
                style="
                  width: 300px;
                  height: 300px;
                  text-align: center;
                  font-size: 30px;
                  margin: 0 auto
                "
              >
                已处理<br /><br />
                {{ order_data.dispose_order.num }}单
              </dv-decoration-9>
            </div>
            <div style="width:33%;display:inline-block;">
              <dv-decoration-9
                :dur="order_data.dispose_order_amount.dur"
                style="
                  width: 300px;
                  height: 300px;
                  text-align: center;
                  font-size: 30px;
                  margin: 0 auto
                "
              >
                总金额<br /><br />￥{{ order_data.dispose_order_amount.amount }}
              </dv-decoration-9>
            </div>
          </div>
        </div>
        <div style="width:50%;display:inline-block">
          <dv-scroll-board
            :config="config"
            style="width: 100%; height: 500px; margin-top: 20px; padding: 20px"
          />
        </div>
       <div style="width:50%;display:inline-block">
          <dv-scroll-board
            :config="sale_config"
            style="width: 100%; height: 500px; margin-top: 20px; padding: 20px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
import Speech from "speak-tts";
export default {
  mixins: [drawMixin],
  data() {
    return {
      order_data: {},
      music_path: "https://imgs.xinfucai.cn/Static/audio/xindingdan.mp3",
      config: {
        headerHeight: 100,
        header: [
          "<span style='font-size:30px;'>排名</span>",
          "<span style='font-size:30px;'>姓名</span>",
          //  "<span style='font-size:30px;'>正在处理</span>",
          "<span style='font-size:30px;'>已处理(天/月)</span>",
          "<span style='font-size:30px;'>金额(天/月)</span>",
        ],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 5,
        carousel: "single",
        waitTime: 4000,
      },
      sale_config: {
        headerHeight: 100,
        header: [
          "<span style='font-size:30px;'>排名</span>",
          "<span style='font-size:30px;'>姓名</span>",
          //  "<span style='font-size:30px;'>正在处理</span>",
          "<span style='font-size:30px;'>单数(天/月)</span>",
          "<span style='font-size:30px;'>金额(天/月)</span>",
        ],
        data: [],
        // index: true,
        // columnWidth: [150],
        align: ["center"],
        rowNum: 5,
        carousel: "single",
        waitTime: 4000,
      },
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      websock: null,
      cdata: {},
      is_play: false,
    };
  },
  components: {},
  mounted() {
    this.timeFn();
    // this.cancelLoading();
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    this.websock.close(); //离开路由之后断开websocket连接
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    playStart() {
      this.is_play = true;
      this.$refs.audio.src = this.music_path;
      this.$refs["audio"].play();
    },
    playStop() {
      this.is_play = false;
    },
    //语音播报
    reloadYP() {
      this.$refs.audio.src = this.music_path;
      if (this.is_play) {
        //   this.$refs['audio'].pause();
        //   this.is_play = false
        //   this.buttonText = 'stopped'
        // } else {
        this.$refs["audio"].play();
        // this.is_play = true
        // this.buttonText = 'starting'
      }
    },

    speechInit() {
      this.speech = new Speech();
      this.speech.setLanguage("zh-CN");
      this.speech.init().then(() => {
        console.log("语音播报初始化完成...");
      });
    },

    //  语音播报
    speak(text) {
      console.log(text);
      console.log(this.speech);
      this.speech.speak({ text: text }).then(() => {
        console.log("播报完成...");
      });
    },

    //语音播报结束

    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },

    initWebSocket() {
      //初始化weosocket
      // const wsuri = "ws://127.0.0.1:9502";
      // const wsuri = "wss://ws.hzsp668.com";
      // const wsuri = "wss://ws.hfx.xinfucai.cn";
      // const wsuri = "wss://ws.kaida668.com";
      const wsuri = "wss://ws.jiaqifucai.cn";
      // const wsuri = "ws://cxjc.ws.hzsp668.com";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },
    websocketonopen() {
      //连接建立之后执行send方法发送数据
      // let region_id = "138_kefu";
      // let region_id = '52_kefu';
      // let region_id = '163_kefu';
      // let region_id = '138_kefu';
      // let region_id = '343_kefu';
      let region_id = '140_kefu';
      let original_data = {
        class: "Login",
        action: "loginDp",
        content: {
          region_id,
        },
      };
      this.websocketsend(JSON.stringify(original_data));
      //请求数据
      // let get_data = {
      //   class: "Login",
      //   action: "loginDp",
      //   content: {
      //     region_id,
      //   },
      // };
      //  this.websocketsend(JSON.stringify(get_data));
    },
    websocketonerror() {
      //连接建立失败重连
      this.initWebSocket();
    },
    websocketonmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      let config = this.config;
      this.order_data = redata.kefu_data;
      this.cancelLoading();
      config= {
        headerHeight: 100,
        header: [
          "<span style='font-size:30px;'>排名</span>",
          "<span style='font-size:30px;'>客服</span>",
          //  "<span style='font-size:30px;'>正在处理</span>",
          "<span style='font-size:30px;'>已处理(天/月)</span>",
          "<span style='font-size:30px;'>金额(天/月)</span>",
        ],
        data: redata.kefu_user_list,
        align: ["center"],
        rowNum: 5,
        carousel: "single",
        columnWidth: [100,200],
        waitTime: 4000,
      }
      this.config = config;

      let sale_config = this.sale_config;
      this.cancelLoading();
      sale_config= {
        headerHeight: 100,
        header: [
          "<span style='font-size:30px;'>排名</span>",
          "<span style='font-size:30px;'>业务</span>",
          //  "<span style='font-size:30px;'>正在处理</span>",
          "<span style='font-size:30px;'>单数(天/月)</span>",
          "<span style='font-size:30px;'>金额(天/月)</span>",
        ],
        data: redata.sale_user_list,
        align: ["center"],
        rowNum: 5,
        carousel: "single",
        columnWidth: [100,200],
        waitTime: 4000,
      }
      this.sale_config = sale_config;
      if (redata.type == "yuyin") {
        this.reloadYP();
      }
      console.log(redata);
    },
    websocketsend(Data) {
      //数据发送
      this.websock.send(Data);
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
      this.initWebSocket();
    },
  },
};
</script>
<style lang="scss">
@import "../assets/scss/index.scss";
</style>
